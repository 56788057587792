<template>
  <scroll-container>
    <container>
      <card-section>
        <template #title> Resetting your password.. </template>
      </card-section>
    </container>
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],

  async mounted() {
    const user = this.$store.state.session.user.user_id
    if (!user) {
      return this.$store.dispatch('logout')
    }

    await this.$store.dispatch('User/partialUpdate', {
      selected: [
        {
          user_id: user,
          type: 'user',
          user_is_using_temp_pass: 1
        }
      ]
    })

    return this.$store.dispatch('to', '/home')
  }
}
</script>
